import React, { useState, useEffect } from 'react';
import { TextField, Button, IconButton, Grid, Paper, Typography, Container, Snackbar, Alert } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import { firestore } from '../../Firebase/firebaseConfig'; // Import the firestore instance
import { getAuth } from 'firebase/auth';
import { collection, addDoc, getDocs, deleteDoc, doc, updateDoc } from 'firebase/firestore';

const DeliverySettings = () => {
    const [deliveryFees, setDeliveryFees] = useState([]);
    const [restaurantID, setRestaurantID] = useState(null);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    useEffect(() => {
        const auth = getAuth();
        const user = auth.currentUser;
        if (user) {
            setRestaurantID(user.uid);
            loadDeliveryFees(user.uid);
        } else {
            console.error('User not authenticated');
        }
    }, []);

    const loadDeliveryFees = async (restaurantID) => {
        try {
            const feeVariantsCollectionRef = collection(firestore, 'restaurant', restaurantID, 'delivery', 'settings', 'feeVariants');
            const querySnapshot = await getDocs(feeVariantsCollectionRef);
            if (!querySnapshot.empty) {
                const fees = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
                setDeliveryFees(fees);
                setSnackbarMessage('Delivery fees loaded successfully.');
                setSnackbarSeverity('success');
                setSnackbarOpen(true);
            } else {
                setDeliveryFees([{ distanceFrom: '', distanceTo: '', fee: '', minOrderIncrease: '', freeFromAmount: '' }]);
            }
        } catch (error) {
            console.error('Error loading delivery fees: ', error);
            setSnackbarMessage('Error loading delivery fees.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleFeeChange = (index, event) => {
        const values = [...deliveryFees];
        values[index][event.target.name] = event.target.value;
        setDeliveryFees(values);
    };

    const handleAddFee = () => {
        const newFee = { distanceFrom: '', distanceTo: '', fee: '', minOrderIncrease: '', freeFromAmount: '' };
        setDeliveryFees([...deliveryFees, newFee]);
    };

    const handleRemoveFee = async (index) => {
        const fee = deliveryFees[index];
        if (fee.id) {
            await deleteDoc(doc(firestore, 'restaurant', restaurantID, 'delivery', 'settings', 'feeVariants', fee.id));
        }
        const values = [...deliveryFees];
        values.splice(index, 1);
        setDeliveryFees(values);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        if (!restaurantID) {
            console.error('Restaurant ID is not set');
            setSnackbarMessage('Restaurant ID is not set.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
            return;
        }

        // Validate no overlapping ranges
        for (let i = 0; i < deliveryFees.length; i++) {
            for (let j = i + 1; j < deliveryFees.length; j++) {
                if (
                    (deliveryFees[i].distanceFrom < deliveryFees[j].distanceTo &&
                    deliveryFees[i].distanceTo > deliveryFees[j].distanceFrom)
                ) {
                    alert('Delivery fee ranges cannot overlap.');
                    return;
                }
            }
        }

        try {
            const feeVariantsCollectionRef = collection(firestore, 'restaurant', restaurantID, 'delivery', 'settings', 'feeVariants');
            
            const promises = deliveryFees.map(async (fee) => {
                if (fee.id) {
                    const feeDocRef = doc(firestore, 'restaurant', restaurantID, 'delivery', 'settings', 'feeVariants', fee.id);
                    await updateDoc(feeDocRef, fee);
                } else {
                    await addDoc(feeVariantsCollectionRef, fee);
                }
            });

            await Promise.all(promises);
            setSnackbarMessage('Settings saved successfully.');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
        } catch (error) {
            console.error('Error saving settings: ', error);
            setSnackbarMessage('Error saving settings.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        }
    };

    const handleSnackbarClose = () => {
        setSnackbarOpen(false);
    };

    return (
        <Container maxWidth="md">
            <form onSubmit={handleSubmit}>
                <Paper style={{ padding: '20px', marginTop: '20px' }}>
                    <Typography variant="h6">Delivery Fees</Typography>
                    {deliveryFees.map((fee, index) => (
                        <Grid container spacing={2} alignItems="center" key={index} style={{ marginBottom: '10px' }}>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    label="From (km)"
                                    type="number"
                                    name="distanceFrom"
                                    value={fee.distanceFrom}
                                    onChange={(event) => handleFeeChange(index, event)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    label="To (km)"
                                    type="number"
                                    name="distanceTo"
                                    value={fee.distanceTo}
                                    onChange={(event) => handleFeeChange(index, event)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    label="Fee (€)"
                                    type="number"
                                    name="fee"
                                    value={fee.fee}
                                    onChange={(event) => handleFeeChange(index, event)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    label="Min Order (€)"
                                    type="number"
                                    name="minOrderIncrease"
                                    value={fee.minOrderIncrease}
                                    onChange={(event) => handleFeeChange(index, event)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    label="Free From (€)"
                                    type="number"
                                    name="freeFromAmount"
                                    value={fee.freeFromAmount}
                                    onChange={(event) => handleFeeChange(index, event)}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={12} sm={1}>
                                <IconButton onClick={() => handleRemoveFee(index)}>
                                    <DeleteIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    ))}
                    <Button
                        variant="outlined"
                        startIcon={<AddIcon />}
                        onClick={handleAddFee}
                        style={{ marginTop: '10px' }}
                    >
                        Add Fee Range
                    </Button>
                </Paper>
                <Button
                    type="submit"
                    variant="contained"
                    color="primary"
                    style={{ marginTop: '20px', display: 'block', marginLeft: 'auto', marginRight: 'auto' }}
                >
                    Save Settings
                </Button>
            </form>
            <Snackbar
                open={snackbarOpen}
                autoHideDuration={3000}
                onClose={handleSnackbarClose}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                style={{ marginBottom: '40px' }} // Adjust this value to position the Snackbar below the Save Settings button
            >
                <Alert onClose={handleSnackbarClose} severity={snackbarSeverity}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
        </Container>
    );
};

export default DeliverySettings;