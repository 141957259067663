import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, IconButton, Tooltip, Divider, Chip } from '@mui/material';
import CreateIcon from '@mui/icons-material/Create';
import PersonIcon from '@mui/icons-material/Person';
import QrCodeIcon from '@mui/icons-material/QrCode2';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Logo from '../../Logo/NOWAITY/4x/LOGO V2Png File.png';
import { useAuth } from '../../Firebase/AuthContext'; // Import useAuth
import LogoutIcon from '@mui/icons-material/Logout';
import InsightsSharpIcon from '@mui/icons-material/InsightsSharp';
import ReceiptIcon from '@mui/icons-material/Receipt';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
//import PaymentIcon from '@mui/icons-material/Payment';
//import DeliveryDiningIcon from '@mui/icons-material/DeliveryDining';

const SideNav = () => {
  const [isOpen, setIsOpen] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const { logout } = useAuth(); // Destructure logout from useAuth

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  const navigationOptions = [
    { name: 'Orders', path: '/admin/dashboard', icon: <ReceiptIcon style={{ color: '#15A5C2' }} />, style: { color: 'white' } },
   // { name: 'Delivery', path: '/admin/delivery/settings', icon: <DeliveryDiningIcon style={{ color: '#15A5C2' }} />, style: { color: 'white' }, isNew: true },
    { name: 'Menu', path: '/admin/create', icon: <CreateIcon style={{ color: '#15A5C2' }} />, style: { color: 'white' } },
   // { name: 'Pay', path: '/admin/pay', icon: <PaymentIcon style={{ color: '#15A5C2' }} />, style: { color: 'white' } },
    { name: 'QR Code', path: '/admin/QRCode', icon: <QrCodeIcon style={{ color: '#15A5C2' }} />, style: { color: 'white' } },
    { name: 'Profile', path: '/admin/personal', icon: <PersonIcon style={{ color: '#15A5C2' }} />, style: { color: 'white' } }, 
    { name: 'Bank', path: '/admin/billing', icon: <AccountBalanceIcon style={{ color: '#15A5C2' }} />, style: { color: 'white' } },
    { name: 'Contact', path: '/admin/contact', icon: <ContactSupportIcon style={{ color: '#15A5C2' }} />, style: { color: 'white' } },
  ];

  const renderNavigationOption = (option, index) => (
    <Tooltip title={isCollapsed ? option.name : ''} placement="right" key={index}>
      <ListItem button component={Link} to={option.path}>
        <ListItemIcon>
          {option.icon}
        </ListItemIcon>
        {!isCollapsed && (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <ListItemText primary={option.name} style={{ color: 'white' }} />
            {option.isNew && <Chip label="NEW" color="secondary" size="small" style={{ marginLeft: 4, fontSize: '0.7rem', height: '18px' }} />}
          </div>
        )}
      </ListItem>
    </Tooltip>
  );

  // Placeholder to match the logo height
  const logoPlaceholder = <div style={{ height: '40px' }}></div>; // Adjust the height as per your logo's height

  return (
    <div>
      <Drawer anchor="left" open={isOpen} onClose={toggleDrawer} variant="persistent">
        <div
          style={{
            width: isCollapsed ? 55 : 190,
            backgroundColor: '#333333',
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflowX: 'hidden',
          }}
        >
          {/* Header area with logo and chevron */}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px' }}>
            {isOpen && !isCollapsed ? (
              <img src={Logo} alt="Logo" style={{ height: '30px', maxWidth: '100%' }} />
            ) : (
              logoPlaceholder
            )}
            <IconButton onClick={toggleCollapse} style={{ color: '#15A5C2' }}>
              {isCollapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </div>

          {/* Main content area */}
          <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
            <div>
              <List>
                {navigationOptions.map(renderNavigationOption)}
              </List>
            </div>

            {/* Bottom part - Logout */}
            <div>
              <Divider />
              <ListItem button onClick={logout}>
                <ListItemIcon>
                  <LogoutIcon style={{ color: '#15A5C2', transform: 'scaleX(-1)' }} />
                </ListItemIcon>
                {!isCollapsed && <ListItemText primary="Logout" style={{ color: 'white' }} />}
              </ListItem>
            </div>
          </div>
        </div>
      </Drawer>
    </div>
  );
};

export default SideNav;