import React, { useEffect, useState } from 'react';
import { getFirestore, collection, query, getDocs, doc, writeBatch, getDoc, onSnapshot } from 'firebase/firestore';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardHeader from '@mui/material/CardHeader';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import Pagination from '@mui/material/Pagination';
import Divider from '@mui/material/Divider';

function TabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <Typography component="div" role="tabpanel" hidden={value !== index} id={`simple-tabpanel-${index}`} aria-labelledby={`simple-tab-${index}`} {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Dashboard = () => {
  const [activeOrders, setActiveOrders] = useState([]);
  const [doneOrders, setDoneOrders] = useState([]);
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState(null);
  const [isMovingToDone, setIsMovingToDone] = useState(false);
  const [page, setPage] = useState(1);

  const itemsPerPage = 10;

  const fetchOrderItems = async (userId, orderId, collectionName) => {
    const db = getFirestore();
    const itemsQuery = query(collection(db, `/restaurant/${userId}/${collectionName}/${orderId}/items`));
    const itemsSnapshot = await getDocs(itemsQuery);
    let items = [];
    itemsSnapshot.forEach(doc => {
      items.push(doc.data());
    });
    return items;
  };

  const fetchOrders = (collectionName, updateStateFunc, userId) => {
    const db = getFirestore();
    return onSnapshot(query(collection(db, `/restaurant/${userId}/${collectionName}`)), async (querySnapshot) => {
      let orders = [];
      for (const doc of querySnapshot.docs) {
        const orderData = doc.data();
        orderData.items = await fetchOrderItems(userId, doc.id, collectionName);
        orders.push({ id: doc.id, ...orderData, userId });
      }
      updateStateFunc(orders);
    });
  };

  useEffect(() => {
    const auth = getAuth();
    const db = getFirestore();
    let unsubscribeOrders = () => {};
    let unsubscribeDoneOrders = () => {};

    const unsubscribeAuth = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const userId = user.uid;
        setIsLoading(false);

        unsubscribeOrders = fetchOrders("orders", setActiveOrders, userId);
        unsubscribeDoneOrders = fetchOrders("doneOrders", setDoneOrders, userId);
      }
    });

    return () => {
      unsubscribeAuth();
      unsubscribeOrders();
      unsubscribeDoneOrders();
    };
  }, []);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handlePageChange = (event, value) => {
    setPage(value);
  };

  const handleOrderClick = (order) => {
    setSelectedOrder(order);
    const movingToDone = activeOrders.some(activeOrder => activeOrder.id === order.id);
    setIsMovingToDone(movingToDone);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setSelectedOrder(null);
  };

  const handleConfirmChange = async () => {
    if (selectedOrder) {
      const db = getFirestore();
      const userId = selectedOrder.userId;
      const ordersId = selectedOrder.id;

      const sourceCollection = activeOrders.some(order => order.id === ordersId) ? 'orders' : 'doneOrders';
      const targetCollection = sourceCollection === 'orders' ? 'doneOrders' : 'orders';

      const orderRef = doc(db, `/restaurant/${userId}/${sourceCollection}/${ordersId}`);
      const targetOrderRef = doc(db, `/restaurant/${userId}/${targetCollection}/${ordersId}`);
      const itemsRef = collection(db, `/restaurant/${userId}/${sourceCollection}/${ordersId}/items`);

      try {
        const batch = writeBatch(db);

        const orderDoc = await getDoc(orderRef);
        if (!orderDoc.exists()) {
          throw new Error("Order document does not exist.");
        }

        const itemsSnapshot = await getDocs(itemsRef);
        const items = [];
        itemsSnapshot.forEach((itemDoc) => {
          items.push({ id: itemDoc.id, data: itemDoc.data() });
        });

        batch.set(targetOrderRef, orderDoc.data());
        batch.delete(orderRef);

        items.forEach((item) => {
          const targetItemRef = doc(db, `/restaurant/${userId}/${targetCollection}/${ordersId}/items/${item.id}`);
          const sourceItemRef = doc(db, `/restaurant/${userId}/${sourceCollection}/${ordersId}/items/${item.id}`);

          batch.set(targetItemRef, item.data);
          batch.delete(sourceItemRef);
        });

        await batch.commit();
        handleCloseDialog();
      } catch (e) {
        console.error("Batch operation failed: ", e);
      }
    }
  };

  return (
    <Box sx={{ width: '100%', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', width: 'fit-content' }}>
        <Tabs value={value} onChange={handleChange} centered>
          <Tab label="Active" {...a11yProps(0)} sx={{ minWidth: 250, width: '50%' }} />
          <Tab label="Done" {...a11yProps(1)} sx={{ minWidth: 250, width: '50%' }} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {activeOrders.slice((page - 1) * itemsPerPage, page * itemsPerPage).map((order) => (
          <Card key={order.id} sx={{ minWidth: 275, margin: 2 }} onClick={() => handleOrderClick(order)}>
            <CardHeader
  title={`Room ${order.tableNumber}`}
  sx={{ 
    backgroundColor: '#f5f5f5', 
    borderBottom: '1px solid #ccc', 
    padding: '8px 16px'  // Adjust the padding for a smaller header
  }}
  titleTypographyProps={{ 
    variant: 'h6',  // Use a smaller typography variant
    fontSize: '1rem',  // You can fine-tune the font size
    fontWeight: 'bold', // Optional: reduce the boldness to make it more compact
  }}
  />
            <CardContent>
              {order.items.map((item, index) => (
                <Typography key={index} variant="body2">
                  {item.itemCount} x {item.itemName}
                </Typography>
              ))}
            </CardContent>
          </Card>
        ))}
        <Pagination 
          count={Math.ceil(activeOrders.length / itemsPerPage)} 
          page={page} 
          onChange={handlePageChange} 
          sx={{ marginTop: 2 }} 
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        {doneOrders.map((order) => (
          <Card key={order.id} sx={{ minWidth: 275, margin: 2 }} onClick={() => handleOrderClick(order)}>
            <CardHeader
              title={`Table Number: ${order.tableNumber}`}
              sx={{ backgroundColor: '#f5f5f5', borderBottom: '1px solid #ccc' }}
            />
            <CardContent>
              <Divider />
              {order.items.map((item, index) => (
                <Typography key={index} variant="body2">
                  {item.itemCount} x {item.itemName}
                </Typography>
              ))}
            </CardContent>
          </Card>
        ))}
      </TabPanel>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
          <DialogContentText>
            {isMovingToDone ? 'Is this order done?' : 'Set this order to active again?'}
          </DialogContentText>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'space-between', padding: 2 }}>
          <Button onClick={handleConfirmChange} sx={{ backgroundColor: 'rgba(0, 128, 0, 0.1)', flexGrow: 1, marginRight: 1 }}>
            <CheckCircleOutlineIcon style={{ fontSize: 40, color: 'green' }} />
          </Button>
          <Button onClick={handleCloseDialog} sx={{ backgroundColor: 'rgba(255, 0, 0, 0.1)', flexGrow: 1, marginLeft: 1 }}>
            <HighlightOffIcon style={{ fontSize: 40, color: 'red' }} />
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default Dashboard;
