import React, { useState } from 'react';
import { TextField, Button, Stack, Box, FormControl, FormHelperText } from '@mui/material';
import { Link, useNavigate } from 'react-router-dom';
import { doc, setDoc } from 'firebase/firestore';
import { auth, firestore } from '../Firebase/firebaseConfig';
import { createUserWithEmailAndPassword, sendEmailVerification } from 'firebase/auth';

import ReactFlagsSelect from 'react-flags-select';
import COUNTRY_CODES from '../Country/country.js';
import Logo from '../Logo/NOWAITY/4x/LOGO DESIGN Png File.png';

const RegisterForm = () => {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [restaurantName, setRestaurantName] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');
    const navigate = useNavigate();
    const [selectedCountry, setSelectedCountry] = useState('');
    const [passwordError, setPasswordError] = useState('');
    const [repeatPasswordError, setRepeatPasswordError] = useState('');
    const [countryError, setCountryError] = useState('');

    const handleSubmit = async (event) => {
        event.preventDefault();
        setPasswordError('');
        setRepeatPasswordError('');
        setCountryError('');

        if (!password || password.length < 8) {
            setPasswordError('Password must be at least 8 characters long.');
            return;
        }

        if (password !== repeatPassword) {
            setRepeatPasswordError('Passwords do not match.');
            return;
        }

        if (!selectedCountry) {
            setCountryError('Country selection is required.');
            return;
        }

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const uid = userCredential.user.uid;

            await sendEmailVerification(userCredential.user);

            await setDoc(doc(firestore, `restaurant/${uid}/branch/owner`), {
                firstName: firstName,
                lastName: lastName,
                email: email,
            });

            await setDoc(doc(firestore, `restaurant/${uid}/branch/local`), {
                localName: restaurantName,
            });

            await setDoc(doc(firestore, `restaurant/${uid}/branch/address`), {
                country: selectedCountry,
            });

            navigate('/registration-success', { state: { email } });
        } catch (error) {
            console.error('Registration Error:', error);
        }
    };

    return (
        <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" minHeight="100vh" bgcolor="#FCFCFC">
            <img src={Logo} alt="Company Logo" style={{ width: '350px', height: 'auto', marginBottom: '20px'}}/>
            <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" bgcolor="#FCFCFC">
                <form onSubmit={handleSubmit}>
                    <Stack spacing={2} sx={{ width: { xs: '90%', sm: 300 }, margin: 'auto', padding: 2, bgcolor: 'white', borderRadius: '12px', boxShadow: 3, p: 3, mt: 1 }}>
                        <h2 style={{ textAlign: 'center' }}>Create Account</h2>
                        <Stack direction="row" spacing={2}>
                            <TextField label="First Name" onChange={e => setFirstName(e.target.value)} value={firstName} required />
                            <TextField label="Last Name" onChange={e => setLastName(e.target.value)} value={lastName} required />
                        </Stack>
                        <TextField type="email" label="Email" onChange={e => setEmail(e.target.value)} value={email} required />
                        <TextField 
                            type="password" 
                            label="Password" 
                            onChange={e => setPassword(e.target.value)} 
                            value={password} 
                            error={!!passwordError}
                            helperText={passwordError || 'Password must be at least 8 characters long.'}
                            required 
                        />
                        <TextField 
                            type="password" 
                            label="Repeat Password" 
                            onChange={e => setRepeatPassword(e.target.value)} 
                            value={repeatPassword} 
                            error={!!repeatPasswordError}
                            helperText={repeatPasswordError}
                            required 
                        />
                        <TextField label="Company Name" onChange={e => setRestaurantName(e.target.value)} value={restaurantName} required />
                        
                        <FormControl error={!!countryError} required>
                            <ReactFlagsSelect 
                                selected={selectedCountry}
                                onSelect={code => setSelectedCountry(code)}
                                countries={Object.keys(COUNTRY_CODES)}
                                customLabels={COUNTRY_CODES}
                                searchable
                                placeholder="Select country"
                            />
                            {countryError && <FormHelperText>{countryError}</FormHelperText>}
                        </FormControl>
                        
                        <Button variant="contained" color="primary" type="submit">Register</Button>
                        <small style={{ textAlign: 'center' }}>Already have an account? <Link to="/login">Login Here</Link></small>
                    </Stack>
                </form>
            </Box>
        </Box>
    );
};

export default RegisterForm;
