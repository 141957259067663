import React, { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';
import { Container, Box, Typography, TextField, Button, MenuItem, FormControl } from '@mui/material';

const Contact = () => {
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [userUID, setUserUID] = useState(null);

  useEffect(() => {
    const auth = getAuth();
    onAuthStateChanged(auth, user => {
      if (user) {
        setUserUID(user.uid);
      }
    });
  }, []);

  const sendEmail = async (e) => {
    e.preventDefault();
    
    // Formatting the subject line with the selected option and userUID
    const formattedSubject = `${subject} - ${userUID}`;
    
    const emailInfo = {
      to: 'info@nowaity.com', // Replace with the actual recipient's email address
      subject: formattedSubject,
      text: message,
      html: `<p>${message}</p>`, // Sending the message as HTML for email formatting
    };
  
    try {
      // Make sure to replace the URL with your actual backend endpoint
      await axios.post('https://us-central1-nowaitr-70bf2.cloudfunctions.net/api/send-email', emailInfo);
      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again later.');
    }
  };
  return (
    <Container maxWidth="sm" style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center', height: '100vh' }}>
      <Typography variant="h4" component="h1" gutterBottom>
        We're Here to Help!
      </Typography>
      <Typography variant="subtitle1" component="p" gutterBottom>
        Have questions or feedback? Let us know how we can assist you.
      </Typography>
      <Box component="form" onSubmit={sendEmail} sx={{ mt: 1, width: '100%' }}>
        <FormControl fullWidth>
          <TextField
            select
            label="Reason for contacting"
            value={subject}
            onChange={(e) => setSubject(e.target.value)}
            required
            variant="outlined"
            margin="normal"
            SelectProps={{ sx: { height: 40 } }} // Makes the select input thinner
          >
            <MenuItem value="Cancel my subscription">Cancel my subscription</MenuItem>
            <MenuItem value="Feature Request">Feature Request</MenuItem>
            <MenuItem value="Billing Inquiry">Billing Inquiry</MenuItem>
            <MenuItem value="Technical Support">Technical Support</MenuItem>
            <MenuItem value="Other">Other</MenuItem>
          </TextField>
          <TextField
            label="Your Message"
            multiline
            rows={4}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
            required
            variant="outlined"
            margin="normal"
            fullWidth
            // Additional style to increase the horizontal padding within the input box, making the text area wider
            InputProps={{ sx: { paddingX: 4 } }}
          />
          <Button type="submit" variant="contained" sx={{ mt: 3, mb: 2 }}>
            Send
          </Button>
        </FormControl>
      </Box>
    </Container>
  );
};

export default Contact;